<template>
  <div>
    <p class="font-medium text-black mb-2.5">Would you like to guarantee a response?</p>
    <div
      class="bg-white cursor-pointer flex-1 border-2 border-gray-100 booking-option-shadow flex sm:flex-row flex-col rounded-xl"
      :class="{ 'border-pink-500': wantsGuaranteedResponse }"
      @click.prevent="toggleWantsGuaranteedResponse"
    >
      <div class="flex">
        <div class="grid grid-cols-6 py-5 px-5.5">
          <div class="col-span-5">
            <div class="flex items-center space-x-3">
              <h3 class="font-semibold text-sm text-blue-800">
                Guaranteed Response <span class="text-gray-500">(within 48 hours)</span>
              </h3>
            </div>
            <p class="text-sm text-gray-500 mt-2">
              This option does not obligate the invitee to accept the pitch, but it does require his response within 48 hours, or you will get a full refund.
            </p>
          </div>
          <div class="flex justify-end font-semibold text-sm text-blue-800">
            ${{ parseFloat(price).toFixed(0) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AddGuaranteedResponse',
    props: {
      price: Number,
      entity: String,
    },

    computed: {
      wantsGuaranteedResponse() {
        return this.$store.getters[`${this.entity}Booking/wantsGuaranteedResponse`];
      }
    },

    methods: {
      toggleWantsGuaranteedResponse() {
        this.$store.commit(`${this.entity}Booking/setWantsGuaranteedResponse`, !this.wantsGuaranteedResponse);
      },
    },
  }
</script>
