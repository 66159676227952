<template>
    <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle mx-40">
            <div class="bg-lightBlack px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3">
                        <div class="w-full flex">
                            <h3 class="text-2xl leading-6 font-medium text-white" id="modal-title">
                                Max bookings exceeded
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full bg-black px-4 py-3">
                <p class="text-white py-4">
                    You've reached your monthly booking request limit of 50.
                </p>
                <div class="w-full flex justify-center space-x-4 pt-4">
                    <button class="w-56 flex justify-center items-center bg-transparent border-2 border-indigo-gradiant rounded-full py-2 text-white" @click="$router.push({ name: 'Dashboard'})">
                        <span class="font-bold">Back to Home</span>
                    </button>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ProReachedMaxPitchesModal',
}
</script>

<style scoped>
.max-w-60 {
    max-width: 60rem;
}
</style>